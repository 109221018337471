import React, { useState, useEffect } from 'react';
import { Slide } from "react-awesome-reveal";
import { Footer } from "../components/Footer"


const FeatureItem = ({ icon, label }) => {
    return (
        <div className="text-center">
            <div className="bg-black/10 p-1 rounded-sm">
                <img src={icon} className="mx-auto h-6" alt={label} />
            </div>
            <p>{label}</p>
        </div>
    );
};

const FeatureMobile = ({ feature, icon, desc }) => (
    <div className="bg-white rounded-sm py-0 flex justify-between items-center h-full border-t-4 border-[#6C43E0]">
        <div className="w-1/12 flex justify-center">
            <img src={`/img/desc/${icon}.svg`} className=" my-auto" />
        </div>
        <div className="w-11/12 ml-1">
            <p className="text-[#22107D] font-bold">{feature}</p>
            <p className="text-black/70 text-xs">{desc}</p>
        </div>
    </div>
)

export const Home = () => {
    const features = [
        { icon: "/img/icon/shield.svg", label: "Protective" },
        { icon: "/img/icon/speed.svg", label: "Performance" },
        { icon: "/img/icon/gear.svg", label: "Configurable" },
        { icon: "/img/icon/slash.svg", label: "Slash cmds" },
        { icon: "/img/icon/colors.svg", label: "Themes" },
        { icon: "/img/icon/easy.svg", label: "Easy to use" },
        { icon: "/img/icon/database.svg", label: "Storing" },
        { icon: "/img/icon/language.svg", label: "Languagal" }
    ];
    const featuresMobile = [
        {
            feature: "Color themes",
            icon: "colors",
            desc: "Byne offers a system that allows the user to choose a color that will be applied to in all of Bynes awnsers."
        },
        {
            feature: "Configuration",
            icon: "gear",
            desc: "Byne allows for personalized settings, enabling users to modify commands and features to suit their individual requirements."
        },
        {
            feature: "Embed creator",
            icon: "embed",
            desc: "Create beautiful embeds with Byne. This application allows you to configure your own embeds to your liking."
        },
        {
            feature: "Storing system",
            icon: "database",
            desc: "Byne stores your warnings, punishments and other logs in a database, allowing you to view them later."
        },
        {
            feature: "Tag system",
            icon: "tag",
            desc: "Create, edit, view and delete tags. Tags are a great way to organize your server."
        },
        {
            feature: "Multilangual",
            icon: "language",
            desc: "Spanish, Russian, German, English and more languages are supported by Byne."
        },
        {
            feature: "Message & slash commands",
            icon: "pen",
            desc: "Byne offers message and slash commands for all of its features to make it easier and faster to use."
        },
    ];
    const [offsetY, setOffsetY] = useState(0);

    const handleScroll = () => {
        setOffsetY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <>
            {/* <div className="hidden md:block">

            </div> */}
            <div>
                <div
                    className="absolute top-0 left-0 w-full min-h-screen z-10"
                    style={{
                        transform: `translateY(${offsetY * 0.3}px)`,
                        backgroundImage: 'url(/img/other/parallax_1.svg)',
                        backgroundRepeat: 'repeat', // Stellt sicher, dass sich das Bild in X- und Y-Richtung wiederholt
                        backgroundSize: 'auto', // Passt die Größe des Hintergrundbildes an, ohne es zu skalieren
                    }}
                >
                </div>
                <div
                    className="absolute top-0 left-0 w-full min-h-screen z-0"
                    style={{
                        transform: `translateY(${offsetY * 0.1}px)`,
                        backgroundImage: 'url(/img/other/parallax_2.svg)',
                        backgroundRepeat: 'repeat', // Stellt sicher, dass sich das Bild in X- und Y-Richtung wiederholt
                        backgroundSize: 'auto', // Passt die Größe des Hintergrundbildes an, ohne es zu skalieren
                    }}
                >
                </div>
                <div className="relative z-20 min-h-screen">
                    <div className="block md:hidden font-light text-sm">
                        <div className="bg-gradient-to-b from-[#24244A] to-[#6C43E0]">
                            <div className="flex justify-between text-white mx-2">
                                <div className="w-3/5 my-3">
                                    <div className="text-lg font-bold">
                                        <p>
                                            <span className="text-[#4FD5FF]">Upgrade</span>
                                            {' '}your Discord server experience with{' '}
                                            <span className="text-[#FC5BFF]">Byne</span>
                                        </p>
                                    </div>
                                    <p className="text-white/70 mb-2">Byne - a multilangual, modern, easy to use, moderation bot with many feature to fulfil all your needs</p>
                                    <a className="py-1 px-8 font-medium bg-white text-black rounded-sm" href="#">Invite now</a>
                                </div>
                                <img src="/img/ill/ill_4.svg" className="w-2/5" />
                            </div>
                        </div>
                        <img src="/img/other/bg_4.svg" className="w-screen mb-4" />
                        <div className="flex justify-between mx-2">
                            <img src="/img/ill/ill_1.svg" className="w-1/3" />
                            <div className="ml-4 text-sm">
                                <p>Byne offers a versatile configuration suite, allowing for precise customization to align with your server's
                                    specific requirements. Benefit from effortless setup and personalized adjustments for enhanced functionality.</p>
                                <div className="flex mt-2 gap-4">
                                    <a className="border-2 border-[#8B97FF] px-3 rounded-full">Learn more</a>
                                    <a className="border-2 border-[#8B97FF] px-3 rounded-full">Get started</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src="/img/other/bg_3.svg" className="w-screen" />
                            <div className="bg-gradient-to-b from-[#6C43E0] to-[#5B3CB2] text-white py-4">
                                <div className="flex justify-between mx-2">
                                    <div className="">
                                        <p>Byne provides an extensive suite of commands and tools designed to foster a safe, engaging, and user-friendly environment within your server.</p>
                                        <div className="grid grid-cols-3 gap-2 mt-2 text-xs text-center mr-4 divide-y-2">
                                            {features.map((feature, index) => (
                                                <div key={index} className={`border-t-2 ${index % 3 === 0 ? 'col-span-1' : ''}`}>
                                                    <FeatureItem icon={feature.icon} label={feature.label} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <img src="/img/ill/ill_2.svg" className="w-1/3" />
                                </div>
                            </div>
                        </div>
                        <div className="my-4">
                            <div className="flex justify-between mx-2">
                                <img src="/img/ill/ill_3.svg" className="w-1/3" />
                                <div className="ml-2">
                                    <p>Byne comes with 5 supported languages, easy-to-use slash commands and user friendly buttons.</p>
                                    <img src="/img/ill/flags.svg" className="w-4/5 mt-1" />
                                </div>
                            </div>
                        </div>
                        <div className="bg-gradient-to-b from-[#6C43E0] to-[#5B3CB2] text-white py-2">
                            <p className="mx-2 w-2/4 font-bold">Detailed description of Byne features</p>
                        </div>
                        <div className="grid grid-cols-1 gap-1 m-2">
                            {featuresMobile.map(({ feature, icon, desc }) => (
                                <FeatureMobile key={feature} feature={feature} icon={icon} desc={desc} />
                            ))}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div >
        </>
    )
}