import React, { useState, useEffect } from "react";
import { Tooltip } from 'react-tooltip'
import { useLocation, Link } from 'react-router-dom';

const NavigationIcon = ({ to, image, tooltip, tooltip_id }) => {
    const isActive = useLocation().pathname === to;
    const activeClasses = isActive ? 'bg-[#3E29A1] border-t-4 border-[#7A70FF]/50 hover:bg-[#362587]' : 'hover:bg-[#3E3D58]';

    return (
        <>
            <Link to={to} className={`py-4 ${activeClasses} px-5 transition-all hover:cursor-pointer`} id={tooltip_id}>
                <img src={image} />
            </Link>
            <Tooltip anchorSelect={`#${tooltip_id}`} place="bottom">
                {tooltip}
            </Tooltip>
        </>
    );
};
export const AppBar = () => {
    const [showTopButton, setShowTopButton] = useState(false);
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);

    const handleOpenNavbar = () => {
        setIsNavbarOpen(true);
    };

    const handleCloseNavbar = () => {
        setIsNavbarOpen(false);
    };
    const handleScroll = () => {
        if (window.scrollY > 100) {
            setShowTopButton(true);
        } else {
            setShowTopButton(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);


        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const buttonClasses = `z-50 bg-[#2D2C30] p-4 rounded-md focus:ring fixed right-2 transition-transform duration-300 ease-in-out
     ${showTopButton && !isNavbarOpen ? 'bottom-5 translate-y-0 delay-700' : '-bottom-16 translate-y-full'
        }`;
    const navItems = [
        { to: "/", image: '/img/icon/house.svg', tooltip: 'Homepage', tooltip_id: 'home' },
        { to: "/statistics", image: '/img/icon/statistics.svg', tooltip: "See bot's statistics", tooltip_id: 'statistics' },
        { to: "/discord", image: '/img/icon/user.svg', tooltip: 'Join our discord server', tooltip_id: 'discord' },
        { to: "/commands", image: '/img/icon/gear.svg', tooltip: "Visit Byne's guide", tooltip_id: 'commands' },
        { to: "/contact", image: '/img/icon/contact.svg', tooltip: "Contact", tooltip_id: 'contact' },
    ];
    return (
        <>
            <div className="bg-[#1C1D3B] px-5 drop-shadow-2xl md:py-0 py-2 sticky top-0 z-50 w-screen">
                <div className="flex gap-2 justify-between">
                    <div className="flex">
                        <img src='/img/icon/byne_icon.svg' />
                        <div className="hidden md:block">
                            <div className="flex ml-14">
                                {navItems.map(item => (
                                    <NavigationIcon key={item.tooltip_id} {...item} />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="hidden md:block">
                            <div className="flex items-center text-[#1C1D3B]">
                                <Link to="/invite" className="py-1 px-10 bg-white rounded-full font-bold hover:bg-white/80 transition-all flex gap-3">
                                    <img src='/img/icon/add.svg' />
                                    <p>invite</p>
                                </Link>

                            </div>



                        </div>
                        <div className="block md:hidden">
                            <img src="/img/icon/open.svg" className={`hover:cursor-pointer ${isNavbarOpen ? 'rotate-90' : 'rotate-0'} transition-all duration-500`} onClick={handleOpenNavbar} />
                        </div>
                    </div>

                </div>
            </div>
            <div
                className={`fixed bottom-5 left-1/2 transform -translate-x-1/2 translate-y-0 z-50 transition-transform duration-300 w-4/5 block md:hidden ${isNavbarOpen ? 'translate-y-0' : 'translate-y-20 delay-700'}`}>
                <div className="bg-[#2D2C30] rounded-xl">

                    <div className="flex py-3 px-5 justify-between">
                        <Link to="/"><img src='/img/icon/house.svg' /></Link>
                        <Link to="/statistics"><img src='/img/icon/statistics.svg' /></Link>
                        <Link to="/discord"><img src='/img/icon/user.svg' /></Link>
                        <Link to="/guide"><img src='/img/icon/gear.svg' /></Link>
                        <Link to="/contact"><img src='/img/icon/contact.svg' className="hover:cursor-pointer" /></Link>
                        <img src='/img/icon/close.svg' onClick={handleCloseNavbar} className={`hover:cursor-pointer ${isNavbarOpen ? 'rotate-180' : 'rotate-0'} transition-all duration-1000`} />
                    </div>

                </div>
            </div>
            <button onClick={scrollToTop} className={`z-50 bg-[#2D2C30] p-4 rounded-md fixed right-2 transition-transform duration-300 ease-in-out
                ${showTopButton && !isNavbarOpen ? '-bottom-5 -translate-y-10 delay-700' : '-bottom-10 translate-y-full'
                }`}>
                <img src="/img/icon/arrow_up.svg" alt="Arrow Up" className={`transition-all ${showTopButton && !isNavbarOpen ? 'rotate-0 delay-1000 duration-500' : ' rotate-180'
                    }`} />
            </button>
        </>
    );
}