import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AppBar } from "./components/AppBar"

import { Home } from "./pages/Home"
import { Commands } from "./pages/Commands"
import { Discord } from "./pages/Discord"
import { Statistics } from "./pages/Statistics"

function App() {
  return (
    <div className="h-full bg-[#EBF0FF] text-black text-md" style={{ cursor: 'url(img/other/cursor.svg), default'}}>
      <BrowserRouter>
        <AppBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/commands" element={<Commands />} />
          <Route path="/discord" element={<Discord />} />
          <Route path="/statistics" element={<Statistics />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
