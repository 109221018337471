import React from "react";
import { Slide } from "react-awesome-reveal";

export const Discord = () => {
    return (
        <>
            <div className="bg-cover h-full" style={{ backgroundImage: 'url(img/other/bg2.svg)' }}>

                <div className="h-full flex justify-center items-center relative">
                    <Slide direction="up">
                        <div className="text-center text-xl">
                            <p>You are about to leave this page to join our discord server at</p>
                            <p className="underline text-[#006BCE]">https://discord.gg/oPghn</p>
                            <p className="py-6 px-20 bg-gradient-to-r from-[#BB36F9] to-[#8136F9] text-white rounded-lg text-xl font-bold mt-12 hover:cursor-pointer hover:scale-105 transition-all">JOIN NOW</p>
                        </div>
                    </Slide>
                </div>
            </div>


        </>
    )
}